import * as React from 'react';

import Header from './header';
import PropTypes from 'prop-types';

import './layout.scss';
import Footer from './footer';

const Layout = ({children, heroTitle, headerStyle}) => {

  return (
    <>
      <Header heroTitle={heroTitle} headerStyle={headerStyle}/>
      <main className="container">
        {children}
      </main>
      <Footer/>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerStyle: PropTypes.oneOf(['full', 'none', 'waves-brand', 'waves']),
};

export default Layout;
