import * as React from 'react';

import './footer.scss';

const Footer = () => {

  return (
    <>
      <footer>
        <div className="container">
          &copy; 2022 - Cziráki Kitti
        </div>
      </footer>
    </>
  );
};

export default Footer;
